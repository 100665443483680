import { useEffect, useState } from "react";
import { Container, Row, Col, Alert } from "reactstrap";
import axios from "axios";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Prompt from "../components/Prompt";
import Steps from "../components/Steps";
import Success from "../components/Success";

import CapyProgrammingGif from "../assets/Capybara_Laptop.gif";

// Axios config
const instance = axios.create({ baseURL: "https://api-fuse.genez.io/api/v1" });

export default function Home() {
  const [screen, setScreen] = useState("prompt");
  const [classInfo, setClassInfo] = useState("");
  const [functionsList, setFunctionsList] = useState([]);
  const [startExecution, setStartExecution] = useState(false);
  const [error, setError] = useState<any>(false);
  const [activeStep, setActiveStep] = useState("Generating");
  const [generatedProject, setGeneratedProject] = useState({});
  const [tokenValue, setTokenValue] = useState("");

  const handleGenerate = async () => {
    setScreen("steps");
    setStartExecution(true);

    try {
      const response = await instance.post(`/deploy`, {
        genezioToken: tokenValue,
        classInfo,
        functionsList,
      });

      if (response?.status !== 200) {
        setScreen("prompt");
        setError(true);
        setStartExecution(false);
        return;
      }

      setGeneratedProject(response);
      setActiveStep("Finishing");

      setTimeout(() => {
        setScreen("success");
      }, 3000);
    } catch (error) {
      console.error("An error occurred:", error);
      setScreen("prompt");
      setError(error);
      setStartExecution(false);
    }
  };

  useEffect(() => {
    if (startExecution) {
      const timerId = setTimeout(() => {
        setActiveStep("Deploying");
      }, 60000);

      return () => {
        clearTimeout(timerId);
      };
    }
  }, [startExecution]);

  return (
    <>
      <Navbar />
      <Container>
        {/* TODO: remove this style later */}
        <div style={{ minHeight: "83vh" }} className="d-flex align-items-center">
          <Row className="w-100 mt-5 mt-md-0">
            {/* Error alert */}
            {error && (
              <Alert color="danger">
                {error.response?.data?.message
                  ? error.response?.data?.message
                  : "Error generating and deploying the project. This is just a proof of concept, so it might not work all the time."}
              </Alert>
            )}
            {screen === "prompt" ? (
              <Prompt
                handleGenerate={handleGenerate}
                setClassInfo={setClassInfo}
                setFunctionsList={setFunctionsList}
                setTokenValue={setTokenValue}
                classInfo={classInfo}
                functionsList={functionsList}
                tokenValue={tokenValue}
              />
            ) : screen === "steps" ? (
              <Steps activeStep={activeStep} />
            ) : (
              <Success generatedProject={generatedProject} />
            )}
            <Col md={4} className="d-flex align-items-center justify-content-center">
              <img
                src={CapyProgrammingGif}
                alt="codingCapy"
                style={{
                  width: "220px",
                  height: "320px",
                  objectFit: "contain",
                }}
              />
            </Col>
          </Row>
        </div>
      </Container>
      <Footer />
    </>
  );
}
