import { useState } from "react";
import { Col, Button, Form, FormGroup, Input, Alert } from "reactstrap";
import Select from "react-select";

const Suggestions = [
  {
    label: "user service example",
    classInfo:
      "functionality for CRUD on userService that has a model with name, email and password and the db schema to be called users",
    functionsList: [
      "addUser with adding a new user to the db and return the id",
      "deleteUser return only success",
      "login user and return a jwt",
    ],
  },
  {
    label: "email service example",
    classInfo: "email service",
    functionsList: ["addEmail", "deleteEmail", "getEmails", "markEmailsAsImportant"],
  },
  {
    label: "restaurant table reservation example",
    classInfo: "a class that implements a restaurant table reservation mechanism",
    functionsList: [
      "creates a new reservation",
      "delete a new reservation",
      "list all reservations",
    ],
  },
];

interface PromptProps {
  handleGenerate: () => void;
  setClassInfo: (str: string) => void;
  setFunctionsList: (str: any) => void;
  setTokenValue: (str: any) => void;
  classInfo: string;
  tokenValue: string;
  functionsList: any;
}

const Prompt: React.FC<PromptProps> = ({
  handleGenerate,
  setClassInfo,
  setFunctionsList,
  classInfo,
  tokenValue,
  functionsList,
  setTokenValue,
}) => {
  const [showInfoText, setShowInfoText] = useState(false);
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [addToken, setAddToken] = useState(false);
  const defaultValues = functionsList.map((value: string) => ({ label: value, value }));

  return (
    <Col className="d-flex flex-column align-items-center">
      {/* Title */}
      <h1 className="font-weight-semibold text-32">FUSE</h1>
      {/* Description */}
      <h3 className="font-weight-semibold text-14">
        Generate and deploy apps on genezio in just a few minutes.
      </h3>

      <p className="text-muted cursor-pointer" onClick={() => setShowInfoText(!showInfoText)}>
        How to use it?
      </p>

      {showInfoText && (
        <Alert className="text-center" color="info">
          <p>
            Please provide a detailed description of the backend class you wish to deploy.
            Additionally, outline each function you envision for your class.
          </p>

          <p>If you want to see some examples, click on the buttons below.</p>
        </Alert>
      )}

      {/* Suggestions */}
      <div className="d-flex justify-content-center flex-wrap w-75 mt-4">
        {Suggestions?.map((suggestion) => (
          <p
            key={suggestion.label}
            onClick={() => {
              setClassInfo(suggestion?.classInfo);
              setFunctionsList(suggestion?.functionsList);
            }}
            className="color-purple text-14 font-weight-semibold mx-2 my-1 cursor-pointer"
          >
            {suggestion.label}
          </p>
        ))}
      </div>
      {/* Prompt */}
      <Form className="w-50 mb-1">
        <FormGroup className="my-4">
          <Input
            type="text"
            name="classInfo"
            placeholder="Describe your backend class"
            id="classInfo"
            onChange={(e) => setClassInfo(e.target.value)}
            value={classInfo}
          />
        </FormGroup>

        <FormGroup>
          <Select
            key={defaultValues[0]?.label}
            options={options}
            isMulti={true}
            defaultValue={defaultValues}
            placeholder="Describe the methods"
            onInputChange={(inputValue) => {
              const newOption = { label: inputValue, value: inputValue };
              newOption?.label !== "" && setOptions([newOption]);
            }}
            onChange={(selectedValue) => {
              const selectedFunctions = [];
              // TODO:refactor (this for format the values to push them to my state)
              for (var i = 0; i < selectedValue?.length; i++) {
                selectedFunctions.push(selectedValue[i]?.value);
              }
              setFunctionsList(selectedFunctions);
              setOptions([]);
            }}
            onMenuClose={() => {
              setOptions([]);
            }}
          />
        </FormGroup>
      </Form>

      {/* Add Token */}
      <div className="cursor-pointer d-flex align-items-center justify-content-center mt-2 mb-1">
        <p onClick={() => setAddToken(!addToken)} className="mb-0 mx-1 text-14">
          {addToken ? "-" : "+"} Add token
        </p>
      </div>
      {addToken ? (
        <p className="text-14 text-muted text-decoration-none">
          Generate your token using{" "}
          <a href="https://app.genez.io/settings/tokens" target="_blank" rel="noreferrer">
            this link
          </a>
        </p>
      ) : null}

      {addToken && (
        <>
          <FormGroup className="w-50 my-1 mb-0">
            <Input
              onChange={(e) => setTokenValue(e.target.value)}
              style={{ height: "24px" }}
              type="text"
              name="token"
              id="token"
            />
          </FormGroup>

          {/* <a
            className="text-14 text-muted text-decoration-none"
            href="https://app.genez.io/settings/tokens"
            target="_blank"
            rel="noreferrer"
          >
            In case you don't have a generated token, generate one in a few seconds.
          </a> */}
        </>
      )}

      {/* Generate */}
      <Button
        color="primary"
        size="sm"
        className="mt-4 py-2 px-5 rounded font-weight-semibold"
        onClick={handleGenerate}
        disabled={!tokenValue || !classInfo || !functionsList?.length ? true : false}
      >
        Generate
      </Button>
    </Col>
  );
};

export default Prompt;
