import { Col, Button, Row } from "reactstrap";
import JSZip from "jszip";
import SyntaxHighlighter from "react-syntax-highlighter";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/hljs";
import React from "react";
interface SuccessProps {
  generatedProject: any;
}

const Success: React.FC<SuccessProps> = ({ generatedProject }) => {
  const [activeCode, setActiveCode] = React.useState("");
  const [activeCodeFileName, setActiveCodeFileName] = React.useState("");

  const handleDownload = () => {
    const zip = new JSZip();

    zip.folder("project_files");
    zip.folder("project_sdk");

    for (let i = 0; i < generatedProject?.data?.projectFiles.length; i++) {
      const file = generatedProject?.data?.projectFiles[i];
      zip.file("project_files/" + file?.name, file?.content);
    }

    for (let i = 0; i < generatedProject?.data?.sdkFiles.length; i++) {
      const file = generatedProject?.data?.sdkFiles[i];
      zip.file("project_sdk/" + file?.name, file?.content);
    }

    zip.generateAsync({ type: "blob" }).then((content) => {
      const url = URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = url;
      link.download = "project_files.zip";
      link.click();
      URL.revokeObjectURL(url);
    });
  };

  return (
    <Col md={8} className="d-flex flex-column align-items-center">
      <h4 className="mb-0 font-weight-normal">
        Your app was deployed successfully.
      </h4>
      <a
        className="mt-2 text-14"
        href={generatedProject?.data?.link}
        target="_blank"
        rel="noreferrer"
      >
        Project Dashboard Link
      </a>

      {/* TODO: refactor this code */}
      <div
        style={{
          border: "1px solid #D9D9D9",
          width: "100%",
          height: "auto",
          minHeight: "400px"
        }}
        className="mt-2"
      >
        <Row lg={12} className="h-100">
          <Col lg="5">
            <div className="treeCodeGenerator">
              <h4 className="mb-0 font-weight-normal">File Structure</h4>
              <ul>
                <li>project_files</li>
                <li>
                  <ul>
                    {generatedProject?.data?.projectFiles.map((file: any) => (
                      <li
                        className={
                          activeCodeFileName === "project_files/" + file?.name
                            ? "active-custom-elem"
                            : ""
                        }
                        key={file?.name}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setActiveCode(file?.content);
                          setActiveCodeFileName("project_files/" + file?.name);
                        }}
                      >
                        {file?.name}
                      </li>
                    ))}
                  </ul>
                </li>
                <li>project_sdk</li>
                <li>
                  <ul>
                    {generatedProject?.data?.sdkFiles.map((file: any) => (
                      <li
                        className={
                          activeCodeFileName === "project_sdk/" + file?.name
                            ? "active-custom-elem"
                            : ""
                        }
                        key={file?.name}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setActiveCode(file?.content);
                          setActiveCodeFileName("project_sdk/" + file?.name);
                        }}
                      >
                        {file?.name}
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
          </Col>
          <Col
            lg="7"
            style={{
              maxHeight: "400px",
              overflow: "auto"
            }}
          >
            <SyntaxHighlighter
              language="javascript"
              style={{
                ...tomorrow
                // , filter: "saturate(2)"
              }}
              wrapLines={true}
            >
              {activeCode}
            </SyntaxHighlighter>
          </Col>
        </Row>
      </div>

      <Button
        outline
        color="primary"
        className="mt-5 font-weight-semibold px-4"
        onClick={() => handleDownload()}
      >
        Download Project Archive
      </Button>
    </Col>
  );
};

export default Success;
