import logo from "../assets/logo-black.svg";
import { Container } from "reactstrap";

const Footer = () => {
  return (
    <Container>
      <div className="d-flex align-items-center justify-content-center pb-4">
        <p className="mb-0 mx-1 font-weight-semibold">Powered by</p>
        <a href="https://genez.io" target="_blank" rel="noreferrer">
          <img className="mx-1" src={logo} alt="Logo" />
        </a>
      </div>
    </Container>
  );
};

export default Footer;
