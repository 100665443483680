import logo from "../assets/logo-white.svg";
import { Container } from "reactstrap";

const Navbar = () => {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 py-2 bg-dark-purple">
      <Container>
        <img src={logo} alt="Logo" className="py-1" />
      </Container>
    </div>
  );
};

export default Navbar;
