import { Row, Col, Spinner, Alert } from "reactstrap";
import { ReactComponent as QuestionMarkIcon } from "../assets/check-mark-icon.svg";

// generating deploying finishing
const stepsArr = ["Generating", "Deploying", "Finishing"];

interface StepsProps {
  activeStep: string;
}

const Steps: React.FC<StepsProps> = ({ activeStep }) => {
  return (
    <Col className="d-flex flex-column align-items-center justify-content-center">
      <h1 className="font-weight-semibold text-32">FUSE</h1>
      <h3 className="font-weight-semibold text-14">The whole process can take up to 5 minutes.</h3>
      <>
        {stepsArr?.map((step: string) => {
          const currentStep: boolean = activeStep === step ? true : false;
          const isStepPassed: boolean =
            stepsArr.indexOf(activeStep) > stepsArr.indexOf(step) ? true : false;

          return (
            <Row
              style={{ maxWidth: "168px" }}
              className="d-flex w-100 mb-3 align-items-center"
              key={step}
            >
              <Col style={{ maxWidth: "36px" }} className="p-0 w-100">
                {isStepPassed && <QuestionMarkIcon className="purpleCheckMark" />}
                {currentStep && <Spinner className="customSpinner" />}
              </Col>
              <Col className="p-0">
                <h4
                  className={`mb-0 font-weight-normal ${
                    currentStep || isStepPassed ? "color-purple" : ""
                  }`}
                >
                  {step}
                </h4>
              </Col>
            </Row>
          );
        })}
        {activeStep === "Finishing" && (
          <Alert className="w-100 mt-3 text-center">
            You will be redirected to your deployed app.
          </Alert>
        )}
      </>
    </Col>
  );
};

export default Steps;
